import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core';
import OrangeDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/dollar-orange.svg';
import CachedIcon from '@material-ui/icons/Cached';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog';
import {
  useCreateRfqMutation,
  useDocumentListingLazyQuery,
  useGetContractQuery,
  useGetCustomerQuery,
  useGetPrItemByPridForRfqLazyQuery,
  useGetPurchaseReqWithPrQuery,
  usePolicyCheckingLazyQuery,
  useUpdateRfqMutation,
} from 'generated/graphql';
import { SystemMsgs } from '@contract-root/admin-react/src/helpers/Messages/SystemMsg';
import {
  amtStr,
  /* tak ada dalam admi-react */
  // numberFormatter,
} from '@contract-root/admin-react/src/helpers/StringNumberFunction/numFormatter';
import { CommonYupValidation } from '@contract-root/admin-react/src/helpers/YupSchema/yup';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import * as yup from 'yup';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import SnackBarContext from '../App/Store/SnackBarContext';
import { ErrorDialog } from '../../components/Dialog/ErrorDialog';

interface RFQForm {
  prNo: string;
  title: string;
  description: string;
  Attachment: null;
  expectedDate: Date;
}

export const RFQForm = (props: any) => {
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const { id, rfqID }: any = useParams();
  const { mode } = props;
  let history = useHistory();
  const location = useLocation();
  const editData: any = location?.state;
  let [requestedItemListing, setRequestedItemListing] = useState([]);
  let selectedRFQ;
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );

  // Query for contracts
  const {
    loading: conLoading,
    data: { getContract } = { getContract: [] },
  } = useGetContractQuery({
    fetchPolicy: 'network-only',
    variables: { ID: id },
  });

  const [pr, setPr] = useState('');
  //add purchase status to filter

  const [
    fetchPRItemByPRIDForRFQ,
    {
      loading: prItemByRFQLoading,
      data: { getPRItemByPRIDForRFQ } = { getPRItemByPRIDForRFQ: [] },
    },
  ] = useGetPrItemByPridForRfqLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setRequestedItemListing(data?.getPRItemByPRIDForRFQ);
    },
  });

  const {
    loading: loadingGetPR,
    data: { getPurchaseReqWithPR } = { getPurchaseReqWithPR: [] },
  } = useGetPurchaseReqWithPrQuery({
    fetchPolicy: 'network-only',
    variables: {
      contractID: id,
    },
  });

  const {
    loading: customerLoading,
    error: customerError,
    data: { getCustomer } = { getCustomer: [] },
  } = useGetCustomerQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'name' },
    onCompleted: data => {},
  });

  const [
    createRFQ,
    { loading: mutationLoading, error: mutationError },
  ] = useCreateRfqMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: () => {
      setSnackBarMsg(SystemMsgs.createNewRecord());
      setOpenSnackBar(true);
      history.push(`/client-contract/${id}/project-purchase/rfq`);
    },
  });

  const [
    updateRFQ,
    { loading: updateLoading, error: updateError },
  ] = useUpdateRfqMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: () => {
      setSnackBarMsg(SystemMsgs.updateRecord());
      setOpenSnackBar(true);
      history.push(`/client-contract/${id}/project-purchase/rfq`);
    },
  });
  console.log('dlm mana ni');

  // error dialog policy setup
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [
    loadPolicyChecking,
    { loading: loadPolicy, data: { policyChecking } = { policyChecking: {} } },
  ] = usePolicyCheckingLazyQuery({
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
    fetchPolicy: 'network-only',
  });

  // Check policy
  useEffect(() => {
    if (mode) {
      loadPolicyChecking({
        variables: {
          contractID: id,
          category: 'c_rfq',
        },
      });
    }
  }, [mode]);

  //When submitting
  const onSubmit = data => {
    if (mode == 'add') {
      let expectedDate = new Date(data?.expectedDate);
      //add mutation here
      createRFQ({
        variables: {
          rfqInput: {
            purchaseReqID: pr,
            description: data?.description,
            file: files,
            expectedDate: expectedDate.toISOString(),
            contractID: id,
          },
          rfqItemInput: requestedItemListing?.map(ri => {
            return {
              prItemID: ri?.prItemID,
              uomID: ri?.uomID,
              quantity: parseFloat(ri?.requestedQty),
            };
          }),
        },
      });
    } else if (mode == 'edit') {
      updateRFQ({
        variables: {
          ID: editData?.ID,
          rfqInput: {
            purchaseReqID: pr,
            description: data?.description,
            file: files,
            expectedDate: data?.expectedDate,
            contractID: id,
          },
          rfqItemInput: requestedItemListing?.map(ri => {
            return {
              prItemID: ri?.prItemID,
              uomID: ri?.uomID,
              quantity: parseFloat(ri?.requestedQty),
            };
          }),
        },
      });
    }
  };

  const rfqSchema = yup.object().shape({
    prNo: pr ? null : CommonYupValidation.requireField('PR number is required'),
    description: CommonYupValidation.requireField(SystemMsgs.description()),
    expectedDate: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
  });

  const {
    handleSubmit,
    register,
    setValue,
    errors,
    control,
    clearErrors,
    getValues,
    formState,
  } = useForm<RFQForm>({
    mode: 'all',
    defaultValues: {
      prNo: editData ? editData?.purchaseReqID : '',
      description: editData ? editData?.description : '',
      expectedDate: editData ? editData?.expectedDate : new Date(),
      Attachment: null,
    },
    resolver: yupResolver(rfqSchema),
  });
  const { isDirty } = formState;
  const [exitDialog, setExitDialog] = useState(false);

  // Use for attachment
  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment();

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    variables: { refID: editData?.ID },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing);
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL));
    },
  });

  console.log(errors, 'error');
  useEffect(() => {
    if (mode === 'edit') {
      loadDoc();
    }
  }, [mode]);

  let [uomID, setUOMID] = useState(editData?.prItem?.map(v => v?.uomID) ?? []);
  let [uomName, setUOMName] = useState(
    editData?.prItem?.map(v => v?.uom?.code) ?? [],
  );
  const [currIndex, setIndex] = useState(null);
  const [currUOM, setCurrUOM] = useState(null);

  // const onSubmit = (data, submitMode) => {
  //   mode === 'add' && submitMode === 'submit'
  //     ? handlePDFPreview(data)
  //     : submitMode === 'draft'
  //     ? handleDraftSave(data)
  //     : updatePurchaseReq({
  //         variables: {
  //           ID: pId,
  //           purchaseReqInput: {
  //             purchaseType: PurchaseType.Main,
  //             contractID: data.contractID,
  //             subcontractID: data.subcontractID,
  //             // prDate: editData?.prDate,
  //             expectedDate: data.expectedDate,
  //             siteID: data.deliveryTo,
  //             file: files,
  //             contactPerson: {
  //               name: data.contactPerson,
  //               contactNo: data.mobileNo,
  //             },
  //             instructions: data.specialInstruction,
  //             requestedBy: data.requestedBy,
  //             // purchaseStatus: approvablePRChecking?.hasGuideline
  //             //   ? PurchaseStatus.Pending
  //             //   : PurchaseStatus.Submit,
  //           },
  //           prItemListInput:
  //             type === 'On Behalf'
  //               ? data.reqItems?.map(x => {
  //                   return {
  //                     costItemID: x.costItemID,
  //                     uomID: x.uomID,
  //                     requestedQty: parseFloat(x.qty),
  //                     markupPerc: parseFloat(x.markupPerc),
  //                     remarks: x.remarks,
  //                     // outstandingQty: parseFloat(x.qty),
  //                   };
  //                 })
  //               : data.reqItems?.map(x => {
  //                   return {
  //                     costItemID: x.costItemID,
  //                     uomID: x.uomID,
  //                     requestedQty: parseFloat(x.qty),
  //                     remarks: x.remarks,
  //                     // outstandingQty: parseFloat(x.qty),
  //                   };
  //                 }),
  //         },
  //         refetchQueries: [
  //           {
  //             query: GetPurchaseReqDocument,
  //             variables: {
  //               // contractID: id,
  //               purchaseType:
  //                 type === 'pr' ? PurchaseType.Main : PurchaseType.Sub,
  //               orderByDesc: 'prDate',
  //             },
  //           },
  //           {
  //             query: DocumentListingDocument,
  //             variables: {
  //               refID: pId,
  //             },
  //           },
  //         ],
  //       });
  // };

  // const getOpObj = option => {
  //   if (!option) return option;
  //   if (!option.ID)
  //     option = costItemFilter?.find(
  //       op => op?.ID === option && op?.commonStatus === CommonStatus.Active,
  //     );
  //   return option;
  // };
  // const PROJECT_PURCHASE_PATH = `/${CLIENT_CONTRACT}/${id}/${PROJECT_PURCHASE}`;

  // const prType =
  //   type === 'On Behalf'
  //     ? `purchase-requisition/on-behalf`
  //     : `purchase-requisition`;

  //const user = JSON.parse(localStorage.getItem('loggedInUser'));
  return (
    <>
      {loadingGetPR && <Loading />}
      {prItemByRFQLoading && <Loading />}
      {mutationLoading && <Loading />}
      {updateLoading && <Loading />}
      {DocLoad && <Loading />}
      {conLoading && <Loading />}
      {customerLoading && <Loading />}
      {loadPolicy && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() =>
          mode || requestedItemListing?.length > 0
            ? setExitDialog(true)
            : history.push(`/client-contract/${id}/project-purchase/rfq`)
        }
        smTitle={smallTitle.PROJECT_PURCHASES}
        title={user?.companyName}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Menu' },
          { name: 'Purchasing' },
          { name: 'RFQ', current: true },
        ]}
        rightRouteSegments={[
          { name: mode == 'add' ? 'New' : 'Edit', current: true },
        ]}
      />

      <DynamicSubHeader
        title={getContract[0]?.title}
        // rightText={
        //   <IconText
        //     icon={<img src={OrangeDollarIcon} style={{ width: '12px' }} />}
        //     font="xsTitle highlight-text"
        //     children={numberFormatter(
        //       getContract[0]?.revisedContractSum ?? getContract[0]?.contractSum,
        //     )}
        //   />
        // }
        infoLine={
          <>
            <span className="desc">{getContract[0]?.customerDetail?.name}</span>
          </>
        }
      />
      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <ContentWrapper footer multiDynamicInfo>
          <CardContents>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="expectedDate"
                label="Expected Delivery Date"
                control={control}
                format="dd MMM yyyy"
                value={new Date()}
                defaultValue={
                  mode == 'edit' ? selectedRFQ?.expectedDate : new Date()
                }
                onChange={(date: Date | null) => {
                  console.log(date);
                }}
                allowKeyboardControl
                fullWidth
                margin="normal"
                // inputRef={registerCancel({})}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>

            <Controller
              render={({ onChange, onBlur, value }) => (
                <TextField
                  name="prNo"
                  select
                  label="PR No"
                  // required
                  defaultValue={editData?.purchaseReqID}
                  helperText={errors?.prNo?.message}
                  error={errors?.prNo ? true : false}
                  onChange={e => {
                    setPr(e?.target?.value);
                    fetchPRItemByPRIDForRFQ({
                      variables: { ID: e?.target?.value, contractID: id },
                    });
                  }}
                >
                  {getPurchaseReqWithPR?.map((e, i) => (
                    <MenuItem value={e?.ID} key={i}>
                      {e?.docNo}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              select
              name="prNo"
              autoComplete="off"
              control={control}
              margin="normal"
              ref={register}
              required
              helperText={errors?.prNo?.message}
              error={errors?.prNo ? true : false}
            />

            <Controller
              as={TextField}
              name="description"
              label="Description"
              autoComplete="off"
              multiline={true}
              margin="normal"
              control={control}
              ref={register}
              helperText={errors?.description?.message}
              error={!!errors?.description ? true : false}
              required
            />

            <FileUploadInput
              label="Attachment"
              margin="normal"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </CardContents>

          {pr != '' ? (
            <>
              <CardContents
                section={{
                  header: {
                    title: `Requested Item`,
                    onClickAction: () => {
                      console.log(`refreshing`, pr);
                      fetchPRItemByPRIDForRFQ({
                        variables: { ID: pr, contractID: id },
                      });
                    },
                    icon: <CachedIcon />,
                  },
                }}
              >
                <div className="rm-padding table-wrap">
                  <List className="core-list content-wrap full ">
                    <ListItem className="table-header">
                      <ListItemText
                        primary={
                          <Grid container justify="flex-start">
                            <Grid item xs={1}>
                              <div
                                className="xsTitle flex-space "
                                style={{ color: '#ff9800' }}
                              >
                                No.
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div
                                className="xsTitle"
                                style={{ color: '#ff9800' }}
                              >
                                Item
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div
                                className="xsTitle"
                                style={{ color: '#ff9800' }}
                              >
                                UOM
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div
                                className="xsTitle"
                                style={{ color: '#ff9800' }}
                              >
                                Qty
                              </div>
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                    {requestedItemListing?.map((el, i) => (
                      <ListItem
                        style={{ boxShadow: 'none', marginBottom: '0px' }}
                      >
                        <ListItemText
                          key={i}
                          primary={
                            <>
                              <Grid container justify="flex-start">
                                <Grid item xs={1}>
                                  <div className="desc">{`${i + 1}.`}</div>
                                </Grid>
                                <Grid item xs={10}>
                                  <div className="desc">{el?.name}</div>
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  style={{ marginBottom: '-15px' }}
                                >
                                  <IconButton>
                                    <DeleteIcon
                                      fontSize={`small`}
                                      color={`inherit`}
                                      onClick={() => {
                                        console.log('Deleted Something', i);
                                        console.log(
                                          requestedItemListing?.filter(
                                            items =>
                                              items?.costItemID !=
                                              el.costItemID,
                                          ),
                                        );
                                        setRequestedItemListing(
                                          requestedItemListing?.filter(
                                            items =>
                                              items?.costItemID !=
                                              el.costItemID,
                                          ),
                                        );
                                      }}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </>
                          }
                          secondary={
                            <>
                              <Grid container justify="flex-start">
                                <Grid item xs={1}></Grid>
                                <Grid item xs={5}>
                                  <div className="desc text-noflow">
                                    {!!el?.remarks ? el?.remarks : ''}
                                  </div>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={2}>
                                  <div className="xxTitle">{el?.uomCode}</div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div className="xxTitle">
                                    {amtStr(el?.requestedQty)}
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </CardContents>
            </>
          ) : null}

          <ConfirmationDialog
            exitDialog={exitDialog}
            setExitDialog={setExitDialog}
            path={() =>
              history.push(`/client-contract/${id}/project-purchase/rfq`)
            }
          />

          <Footer
            options={[
              {
                name: 'Save',
                color: 'primary',
                onClick: () => {
                  handleSubmit(onSubmit);
                },
              },
            ]}
          />

          <ErrorDialog
            errorDia={errorDialog}
            setErrorDia={setErrorDialog}
            errorMsg={errorMsg}
            errorHeaderMsg={
              errorMsg?.includes('Response')
                ? 'Error'
                : 'No Approval Policy Setup'
            }
            isApprovalPolicy
            onclick={() => {
              history.push(`/client-contract/${id}/project-purchase/rfq`);
            }}
          />
        </ContentWrapper>
      </form>
    </>
  );
};

// import { yupResolver } from '@hookform/resolvers';
// import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
// import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
// import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
// import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
// import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
// import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
// import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
// import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
// import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
// import {
//   Divider,
//   Grid,
//   IconButton,
//   List,
//   ListItem,
//   ListItemText,
//   MenuItem,
// } from '@material-ui/core';
// import TextField from '@material-ui/core/TextField';
// import { AddCircle, Delete, NavigateBeforeSharp } from '@material-ui/icons';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import { KeyboardDatePicker } from '@material-ui/pickers';
// import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
// import { amtNumStr, amtStr } from 'containers/helper/numFormatter';
// import { SystemMsgs } from 'containers/helper/SystemMsg';
// import { CommonYupValidation } from 'containers/helper/yup';
// import {
//   CLIENT_CONTRACT,
//   PROJECT_PURCHASE,
// } from 'containers/HomeModule/HomeRoutes';
// // import { PurchaseContext } from 'containers/OrderProcessingModule/PurchaseContext';
// import {
//   CommonStatus,
//   DocumentListingDocument,
//   GetPurchaseReqDocument,
//   PurchaseStatus,
//   PurchaseType,
//   useCreateRfqMutation,
//   useUpdateRfqMutation,
//   useGetPurchaseReqWithPrQuery,
//   // useApprovableLedgerCheckingLazyQuery,
//   useCreatePurchaseReqMutation,
//   useDocumentListingLazyQuery,
//   useGetContractQuery,
//   useGetCostItemAndUomQuery,
//   // useGetPurchaseReqWithPrQuery,
//   useGetSupplierQuery,
//   // useGetSubcontractLazyQuery,
//   useUpdatePurchaseRequestMutation,
// } from 'generated/graphql';
// import React, { useContext, useEffect, useState } from 'react';
// import { Controller, useFieldArray, useForm } from 'react-hook-form';
// import NumberFormat from 'react-number-format';
// import { useHistory, useLocation, useParams } from 'react-router-dom';
// import * as yup from 'yup';
// // import { CLIENT_CONTRACT, PROJECT_PURCHASE } from '../ProjectPurchaseRoutes';
// import { PurchaseContext } from '../OrderProcessingModule/PurchaseContext';
// interface PurchaseReqForm {
//   requisitionDate: Date;
//   expectedDate: Date;
//   requestedBy: string;
//   deliveryTo: string;
//   contactPerson: string;
//   mobileNo: string;
//   specialInstruction: string;
//   attachment: string;
//   subcontractId?: string;
//   reqItems?: any;
// }

// interface PrProps {
//   type: string;
//   mode: string;
// }

// interface RFQForm {
//   prNo: string;
//   title: string;
//   description: string;
//   Attachment: null;
//   expectedDate: Date;
// }

// export const PurchaseReqSchema = yup.object().shape({
//   requestedBy: CommonYupValidation.requireField(SystemMsgs.requestedBy()),

//   // deliveryTo: CommonYupValidation.requireField(
//   //   SystemMsgs.deliveryAddressSelection(),
//   // ),
//   contactPerson: CommonYupValidation.requireField(SystemMsgs.contactPerson()),
//   // mobileNo: CommonYupValidation.mobileNo(),
//   mobileNo: CommonYupValidation.requireField(SystemMsgs.contactNo()),
//   specialInstruction: CommonYupValidation.requireField(
//     SystemMsgs.specialInstruction(),
//   ),
//   reqItems: yup.array().of(
//     yup.object().shape({
//       costItemID: CommonYupValidation.requireField(SystemMsgs.item()),
//       uomID: CommonYupValidation.requireField(SystemMsgs.uom()),
//       qty: yup
//         .number()
//         .required('Qty cannot be zero')
//         .min(1, SystemMsgs.qty()),
//       // remarks: CommonYupValidation.requireField(SystemMsgs.remarks()),
//     }),
//   ),
// });
// export const RFQForm = (props: any) => {
//   const { type, mode } = props
//   let formMode = mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : 'Resubmit';
//   const { purchaseState, purchaseDispatch } = useContext(
//     PurchaseContext as any,
//   );
//   const [markupPerc, setMarkupPerc] = useState(0);
//   const {
//     files,
//     handleUploadChange,
//     previewFiles,
//     remove: removeFile,
//     handleEditUpload,
//   } = useUploadAttachment();

//   let history = useHistory();
//   const user = JSON.parse(localStorage.getItem('loggedInUser'));
//   let location = useLocation();
//   const editData = location?.state as any;
//   let [ requestedItemListing, setRequestedItemListing ] = useState([]);
//   const [ pr, setPr ] = useState('');
//   const { id, pId }: any = useParams();

//   // const [
//   //   loadApprovableLedgerChecking,
//   //   {
//   //     error: approvableLedgerCheckingError,
//   //     called: approvableLedgerCheckingCalled,
//   //     loading: approvableLedgerCheckingLoading,
//   //     data: { approvableLedgerChecking } = { approvableLedgerChecking: [] },
//   //   },
//   // ] = useApprovableLedgerCheckingLazyQuery({
//   //   variables: {
//   //     docRefID: id,
//   //     docRefTable: 'c_purchaseReq',
//   //     // approvalLevel: editData?.approvalLevel,
//   //   },
//   // });

//   // useEffect(() => {
//   //   if (!approvableLedgerCheckingCalled) {
//   //     loadApprovableLedgerChecking();
//   //   }
//   // }, [approvableLedgerCheckingCalled]);

//   // const approvablePRChecking: any = approvableLedgerChecking;

//   // console.log('approvablePRChecking', approvablePRChecking);

//   const rfqSchema = yup.object().shape({
//     prNo: pr ? null : CommonYupValidation.requireField('PR number is required'),
//     description: CommonYupValidation.requireField
//   })

//   const { data: contractData } = useGetContractQuery({
//     variables: {
//       ID: id,
//     },
//   });

//   const {
//     loading: loadingGetPR,
//     data: { getPurchaseReqWithPR } = { getPurchaseReqWithPR: [] }
//   } = useGetPurchaseReqWithPrQuery({
//     fetchPolicy: 'network-only',
//     variables: {
//       contractID: id,
//     },
//   });

//   // console.log(getPurchaseReqWithPR, 'purchase PR')

//   const {
//     loading: suppLoading,
//     error: suppError,
//     data: { getSupplier } = { getSupplier: [] },
//   } = useGetSupplierQuery({
//     fetchPolicy: 'network-only',
//     variables: { ID: user?.associateID },
//   });

//   const {
//     data: { getCostItem, getUOM } = { getCostItem: [], getUOM: [] },
//   } = useGetCostItemAndUomQuery({
//     fetchPolicy: 'network-only',
//     variables: {},
//   });

//   const [
//     createRFQ,
//     { loading: createLoading, error: createError },
//   ] = useCreateRfqMutation({
//     onError: error => {
//       console.log('error', error);
//     },
//     onCompleted: () => {
//       purchaseDispatch(SystemMsgs.createNewRecord());
//       purchaseState(true);
//       history.push(`/client-contract/${id}/project-purchase/rfq`);
//     },
//   });

//   const [
//     updateRFQ,
//     { loading: updateLoading, error: updateError },
//   ] = useUpdateRfqMutation({
//     onError: error => {
//       console.log('error', error);
//     },
//     onCompleted: () => {
//       purchaseDispatch(SystemMsgs.updateRecord());
//       purchaseState(true);
//       history.push(`/client-contract/${id}/project-purchase/rfq`);
//     },
//   });

//   // const [
//   //   getSubconList,
//   //   {
//   //     loading: subLoading,
//   //     error: subError,
//   //     data: { getSubcontract } = { getSubcontract: [] },
//   //   },
//   // ] = useGetSubcontractLazyQuery({
//   //   variables: { contractID: id },
//   // });

//   // useEffect(() => {
//   //   if (type === 'On Behalf') {
//   //     getSubconList();
//   //   }
//   // }, [type]);
//   const [address, setAddress] = useState(
//     mode === 'add' ? purchaseState?.selectedAddress : editData?.deliveryAddress,
//   );

//   const {
//     handleSubmit,
//     register,
//     errors,
//     control,
//     setValue,
//     watch,
//     setError,
//     clearErrors,
//     trigger,
//     reset,
//   } = useForm<PurchaseReqForm>({
//     defaultValues: {
//       requisitionDate: mode === 'add' ? '' : editData?.prDate,
//       expectedDate: mode === 'add' ? '' : editData?.expectedDate,
//       requestedBy: mode === 'add' ? '' : editData?.requestedBy,
//       specialInstruction: mode === 'add' ? '' : editData?.instructions,
//       deliveryTo: mode === 'add' ? '' : editData?.siteID,
//       contactPerson: editData?.contactPerson?.name,
//       mobileNo: editData?.contactPerson?.contactNo,
//       reqItems:
//         type === 'On Behalf'
//           ? editData?.prItem?.map(x => {
//               return {
//                 name: x.costItem.name,
//                 uomCode: x.uom.code,
//                 costItemID: x.costItemID,
//                 markupPerc: x.markupPerc,
//                 uomID: x.uomID,
//                 qty: x.requestedQty,
//                 remarks: x.remarks,
//               };
//             })
//           : editData?.prItem?.map(x => {
//               return {
//                 name: x.costItem.name,
//                 uomCode: x.uom.code,
//                 costItemID: x.costItemID,
//                 uomID: x.uomID,
//                 qty: x.requestedQty,
//                 remarks: x.remarks,
//               };
//             }),
//     },
//     mode: 'all',
//     resolver: yupResolver(PurchaseReqSchema),
//   });

//   const { fields, append, remove, insert } = useFieldArray({
//     control,
//     name: 'reqItems',
//   });

//   const costItemFilter = getCostItem?.map(x => x);

//   useEffect(() => {
//     if (getCostItem && mode === 'add') {
//       if (type === 'On Behalf') {
//         append({
//           costItemID: '',
//           uomID: '',
//           qty: 0,
//           markupPerc: 0,
//         });
//       } else {
//         append({
//           costItemID: '',
//           uomID: '',
//           qty: 0,
//         });
//       }
//     }
//   }, [append, mode]);

//   const [
//     updatePurchaseReq,
//     { loading: mutationUpdateLoading, error: mutationUpdateError },
//   ] = useUpdatePurchaseRequestMutation({
//     onError: error => {},
//     onCompleted: data => {
//       // history.push(`${PROJECT_PURCHASE_PATH}/${prType}`);
//       setTimeout(() => {
//         history.push({
//           pathname: `${PROJECT_PURCHASE_PATH}/${prType}`,
//           state: { success: true, msgMode: 'update' },
//         });
//       }, 500);
//     },
//   });
//   const [
//     createPurchaseReq,
//     { loading: mutationLoading, error: mutationError },
//   ] = useCreatePurchaseReqMutation({
//     onError: error => {
//       console.log('ERROR', error);
//     },
//     onCompleted: data => {
//       // history.push(`${PROJECT_PURCHASE_PATH}/${prType}`);

//       setTimeout(() => {
//         history.push({
//           pathname: `${PROJECT_PURCHASE_PATH}/${prType}`,
//           state: { success: true, msgMode: 'create' },
//         });
//       }, 500);
//     },
//   });
//   const handlePDFPreview = data => {
//     purchaseDispatch({
//       // files
//       type: 'selectedFiles',
//       payload: files,
//     });
//     // input fields
//     purchaseDispatch({
//       type: 'prFormData',
//       payload: data,
//     });
//     // contract Title
//     purchaseDispatch({
//       type: 'contractTitle',
//       payload: contractData?.getContract[0]?.title,
//     });
//     purchaseDispatch({
//       type: 'selectedAddress',
//       payload: address,
//     });
//     history.push({
//       pathname: `${PROJECT_PURCHASE_PATH}/${prType}/add/preview`,
//       state: { data },
//     });
//   };

//   const mutation = mode === 'add' ? createPurchaseReq : updatePurchaseReq;
//   const handleDraftSave = data => {
//     mutation({
//       variables: {
//         ID: pId,
//         purchaseReqInput: {
//           purchaseType: type === 'pr' ? PurchaseType.Main : PurchaseType.Sub,
//           //contractID: data.contractID,
//           subcontractID: data.subcontractID,
//           prDate: new Date(),
//           expectedDate: data.expectedDate,
//           siteID: data.deliveryTo,
//           contactPerson: {
//             name: data.contactPerson,
//             contactNo: data.mobileNo,
//           },
//           file: files,
//           instructions: data.specialInstruction,
//           requestedBy: data.requestedBy,
//           purchaseStatus: PurchaseStatus.Active,
//         },
//         prItemListInput:
//           type === 'On Behalf'
//             ? data.reqItems?.map(x => {
//                 return {
//                   costItemID: x.costItemID,
//                   uomID: x.uomID,
//                   requestedQty: parseFloat(amtNumStr(x.qty)),
//                   markupPerc: parseFloat(amtNumStr(x.markupPerc)),
//                   remarks: x.remarks,
//                 };
//               })
//             : data.reqItems?.map(x => {
//                 return {
//                   costItemID: x.costItemID,
//                   uomID: x.uomID,
//                   requestedQty: parseFloat(amtNumStr(x.qty)),
//                   remarks: x.remarks,
//                 };
//               }),
//       },
//       refetchQueries: [
//         {
//           query: GetPurchaseReqDocument,
//           variables: {
//             //contractID: id,
//             purchaseType: type === 'pr' ? PurchaseType.Main : PurchaseType.Sub,
//             orderByDesc: 'prDate',
//           },
//         },
//         {
//           query: DocumentListingDocument,
//           variables: {
//             refID: pId,
//           },
//         },
//       ],
//     });
//   };

//   const [
//     loadDoc,
//     { loading: DocLoad, error: DocError, data: DocData },
//   ] = useDocumentListingLazyQuery({
//     fetchPolicy: 'network-only',
//     variables: { refID: pId },
//     onCompleted: data => {
//       handleEditUpload(data?.DocumentListing);
//       previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL));
//     },
//   });

//   useEffect(() => {
//     if (mode === 'edit') {
//       loadDoc();
//     }
//   }, [mode]);

//   let [uomID, setUOMID] = useState(editData?.prItem?.map(v => v?.uomID) ?? []);
//   let [uomName, setUOMName] = useState(
//     editData?.prItem?.map(v => v?.uom?.code) ?? [],
//   );
//   const [currIndex, setIndex] = useState(null);
//   const [currUOM, setCurrUOM] = useState(null);

//   const onSubmit = data => {
//     if (mode === 'add') {
//       let expectedDate = new Date(data?.expectedDate);
//       createRFQ({
//         variables: {
//           rfqInput: {
//             purchaseReqID: pr,
//             description: data?.description,
//             file: files,
//             expectedDate: expectedDate.toISOString(),
//             contractID: id,
//           },
//           rfqItemInput: requestedItemListing?.map(e => {
//             return {
//               prItemID: e?.prItemID,
//               uomID: e?.uomID,
//               quantity: parseFloat(e?.requestedQty),
//             };
//           }),
//         },
//       });
//     } else if (mode === 'edit') {
//       updateRFQ({
//         variables: {
//           ID: editData?.ID,
//           rfqInput: {
//             purchaseReqID: pr,
//             description: data?.description,
//             file: files,
//             expectedDate: data?.expected,
//             contractID: id,
//           },
//           rfqItemInput: requestedItemListing?.map(e => {
//             return {
//               prItemID: e?.prItemID,
//               uomID: e?.uomID,
//               quantity: parseFloat(e?.requestedQty),
//             };
//           }),
//         },
//       });
//     }
//     // mode === 'add' && submitMode === 'submit'
//     //   ? handlePDFPreview(data)
//     //   : submitMode === 'draft'
//     //   ? handleDraftSave(data)
//     //   : updatePurchaseReq({
//     //       variables: {
//     //         ID: pId,
//     //         purchaseReqInput: {
//     //           purchaseType: PurchaseType.Main,
//     //           contractID: data.contractID,
//     //           subcontractID: data.subcontractID,
//     //           // prDate: editData?.prDate,
//     //           expectedDate: data.expectedDate,
//     //           siteID: data.deliveryTo,
//     //           file: files,
//     //           contactPerson: {
//     //             name: data.contactPerson,
//     //             contactNo: data.mobileNo,
//     //           },
//     //           instructions: data.specialInstruction,
//     //           requestedBy: data.requestedBy,
//     //           // purchaseStatus: approvablePRChecking?.hasGuideline
//     //           //   ? PurchaseStatus.Pending
//     //           //   : PurchaseStatus.Submit,
//     //         },
//     //         prItemListInput:
//     //           type === 'On Behalf'
//     //             ? data.reqItems?.map(x => {
//     //                 return {
//     //                   costItemID: x.costItemID,
//     //                   uomID: x.uomID,
//     //                   requestedQty: parseFloat(x.qty),
//     //                   markupPerc: parseFloat(x.markupPerc),
//     //                   remarks: x.remarks,
//     //                   // outstandingQty: parseFloat(x.qty),
//     //                 };
//     //               })
//     //             : data.reqItems?.map(x => {
//     //                 return {
//     //                   costItemID: x.costItemID,
//     //                   uomID: x.uomID,
//     //                   requestedQty: parseFloat(x.qty),
//     //                   remarks: x.remarks,
//     //                   // outstandingQty: parseFloat(x.qty),
//     //                 };
//     //               }),
//     //       },
//     //       refetchQueries: [
//     //         {
//     //           query: GetPurchaseReqDocument,
//     //           variables: {
//     //             // contractID: id,
//     //             purchaseType:
//     //               type === 'pr' ? PurchaseType.Main : PurchaseType.Sub,
//     //             orderByDesc: 'prDate',
//     //           },
//     //         },
//     //         {
//     //           query: DocumentListingDocument,
//     //           variables: {
//     //             refID: pId,
//     //           },
//     //         },
//     //       ],
//     //     });
//   };

//   const getOpObj = option => {
//     if (!option) return option;
//     if (!option.ID)
//       option = costItemFilter?.find(
//         op => op?.ID === option && op?.commonStatus === CommonStatus.Active,
//       );
//     return option;
//   };
//   const PROJECT_PURCHASE_PATH = `/${CLIENT_CONTRACT}/${id}/${PROJECT_PURCHASE}`;

//   const prType =
//     type === 'On Behalf'
//       ? `purchase-requisition/on-behalf`
//       : `purchase-requisition`;

//   //const user = JSON.parse(localStorage.getItem('loggedInUser'));
//   return (
//     <>
//       {mutationLoading && <Loading />}
//       <SnackBarMsg open={false} message={SystemMsgs.createNewRecord()} />
//       <MainHeader
//         onClick={() => {
//           history.push(`/home/rfq`);
//           // purchaseDispatch({
//           //   type: 'reset',
//           //   payload: {},
//           // });
//         }}
//         currency={`MYR`}
//         mainBtn="close"
//         smTitle={/*user?.name*/ 'Supplier App'}
//         title={user?.companyName}
//         routeSegments={[
//           { name: 'RFQ 1' },
//           {
//             name: `RFQ ${type === 'On Behalf' ? '(On Behalf)' : ''}`,
//             current: true,
//           },
//         ]}
//         rightRouteSegments={[{ name: formMode, current: true }]}
//       />

//       <ContentWrapper footer>
//         <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
//           <CardContents
//             section={{
//               header: {
//                 title: 'Supplier Quotation',
//               },
//             }}
//           >
//             <Controller
//               as={KeyboardDatePicker}
//               name="expectedDate"
//               label="Expected Date"
//               control={control}
//               onChange={(date: Date | null) => {
//                 console.log(date);
//               }}
//               format="dd MMM yyyy"
//               value={new Date()}
//               allowKeyboardControl
//               fullWidth
//               margin="normal"
//               //inputRef={register({})}
//               KeyboardButtonProps={{
//                 'aria-label': 'change date',
//               }}
//               //  className="left"
//             />
//             <Controller
//               render={({ onChange, onBlur, value }) => (
//                 <TextField
//                   name="prNo"
//                   select
//                   label="PR No"
//                   // required
//                   defaultValue={editData?.purchaseReqID}
//                   // helperText={errors?.prNo?.message}
//                   // error={errors?.prNo ? true : false}
//                   onChange={e => {
//                     setPr(e?.target?.value);
//                     console.log(e?.target?.value, 'e?.target?.value');
//                     fetchPRItemByPRIDForRFQ({
//                       variables: { ID: e?.target?.value, contractID: id },
//                     });
//                   }}
//                 >
//                   {getPurchaseReqWithPR?.map((e, i) => (
//                     <MenuItem value={e?.ID} key={i}>
//                       {e?.docNo}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//               )}
//               select
//               name="prNo"
//               autoComplete="off"
//               control={control}
//               margin="normal"
//               ref={register}
//               required
//               // helperText={SystemMsgs.prNoSelection()}
//               // error={errors?.prNo ? true : false}
//             />

//             <Controller
//               as={TextField}
//               name="description"
//               label="Description"
//               required
//               autoComplete="off"
//               multiline={true}
//               margin="normal"
//               control={control}
//               ref={register}
//               //helperText={errors?.description?.message}
//               //error={!!errors?.description ? true : false}
//             />
//             <FileUploadInput
//               label="Attachment"
//               margin="normal"
//               files={files}
//               onHandleUploadChange={handleUploadChange}
//               multiple
//               imagePreview={
//                 <>
//                   {previewFiles?.map((v, i) => (
//                     <UploadPreview
//                       remove
//                       key={v}
//                       src={v}
//                       onClick={() => removeFile(i)}
//                       mediaType={
//                         files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
//                       }
//                     />
//                   ))}
//                 </>
//               }
//             />

//             <Footer
//               options={[
//                 {
//                   name: 'Save',
//                   color: 'primary',
//                   onClick: () => {
//                     handleSubmit(onSubmit);
//                   },
//                 },
//               ]}
//             />
//           </CardContents>
//         </form>
//       </ContentWrapper>
//     </>
//   );
// };
// function setPr(value: string) {
//   throw new Error('Function not implemented.');
// }

// function fetchPRItemByPRIDForRFQ(arg0: {
//   variables: { ID: string; contractID: any };
// }) {
//   throw new Error('Function not implemented.');
// }
