import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { useHistory, useLocation, useParams } from 'react-router';
import { attachmentDetailView } from '@contract-root/admin-react/src/helpers/Hooks/attachmentDetailsView';
import { formatDate } from '@contract-root/admin-react/src/helpers/StringNumberFunction/formatDate';
import { amtStr } from '@contract-root/contract-react/src/helpers/StringNumberFunction/numFormatter';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import {
  useDocumentListingQuery,
  useGetContractQuery,
  useGetPurchaseReqQuery,
  useGetRfqQuery,
  useGetSupplierQuery,
} from 'generated/graphql';
import React from 'react';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { supAppModule } from '@ifca-root/react-component/src/helpers/Global/SupplierGlobalVar';

export const RFQDetail = (props: any) => {
  const { rfqID }: any = useParams();
  let history = useHistory();
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const location = useLocation();
  const data: any = location?.state;
  let supplierID = user?.associateID;

  const { loading, data: { getRFQ } = { getRFQ: [] } } = useGetRfqQuery({
    fetchPolicy: 'network-only',
    variables: { ID: rfqID },
  });

  const {
    loading: conLoading,
    data: { getContract } = { getContract: [] },
  } = useGetContractQuery({
    variables: { ID: getRFQ[0]?.contractID },
  });

  const {
    loading: suppLoading,
    error: suppError,
    data: { getSupplier } = { getSupplier: [] },
  } = useGetSupplierQuery({
    fetchPolicy: 'network-only',
    variables: { ID: supplierID },
  });

  const {
    loading: docLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    variables: { refID: rfqID },
  });

  return (
    <>
      {loading && <Loading />}
      {conLoading && <Loading />}
      {docLoading && <Loading />}
      {suppLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/rfq`)}
        smTitle={'Supplier App'}
        title={getSupplier[0]?.name}
        currency={`MYR`}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'e-RFQ' },
          { name: supAppModule.REQUEST_FOR_QUOTATION, current: true },
        ]}
        rightRouteSegments={[{ name: 'Details', current: true }]}
      />
      <DynamicSubHeader
        title={
          <>
            <span className="desc p-r-5">{formatDate(getRFQ[0]?.docDate)}</span>
            <span className="xsTitle">{getRFQ[0]?.docNo}</span>
          </>
        }
        rightText={`Exp: ${formatDate(getRFQ[0]?.expectedDate)}`}
        infoLine={
          <>
            <span className="xxTitle p-r-5">
              {getRFQ[0]?.purchaseReq?.docNo}
            </span>
            <span className="desc">{getRFQ[0]?.description}</span>
          </>
        }
      />

      <ContentWrapper multiDynamicInfo>
        <CardContents section={{ header: { title: 'RFQ Details' } }}>
          <div className="content-wrap">
            <div className="desc">Expected Date</div>
            <div className="xsTitle">{formatDate(data?.expectedDate)}</div>
          </div>
          <div className="content-wrap">
            <div className="desc">PR No.</div>
            <div className="xsTitle">{getRFQ[0]?.purchaseReq?.docNo}</div>
          </div>
          <div className="content-wrap">
            <div className="desc">Description</div>
            <div className="xsTitle">{data?.description}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Attachment(s)</div>
            <div className="xsTitle">
              {attachmentDetailView(DocumentListing)}
            </div>
          </div>
        </CardContents>

        <CardContents section={{ header: { title: 'RFQ Item(s)' } }}>
          <div className="rm-padding table-wrap">
            <List className="core-list content-wrap full " disablePadding>
              <ListItem className="table-header">
                <ListItemText
                  primary={
                    <Grid container>
                      <Grid
                        item
                        xs={1}
                        component="div"
                        className="xsTitle flex-space c-orange"
                      >
                        No.
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        component="div"
                        className="xsTitle flex-space c-orange"
                      >
                        Items
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        component="div"
                        className="xsTitle c-orange center-text"
                      >
                        UOM
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        component="div"
                        className="xsTitle c-orange center-text"
                      >
                        Qty
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
              {getRFQ[0]?.rfqItem?.map((v, i) => (
                <ListItem
                  style={{ boxShadow: 'none', marginBottom: '0px' }}
                  divider={true}
                >
                  <Grid container item xs={1}>
                    <Grid>
                      <div className="desc">{i + 1}.</div>
                    </Grid>
                  </Grid>

                  <Grid container item xs={5}>
                    <Grid>
                      <div className="xsTitle flex-space">
                        {v?.prItem?.costItem?.name}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6}></Grid>

                    <Grid item xs={3}>
                      <div className="xsTitle center-text">{v?.uom?.code}</div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="xsTitle center-text">
                        {amtStr(v?.quantity)}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid>
                      <div
                        className="desc text-noflow"
                        style={{ color: '#898989' }}
                      >
                        {!!v?.prItem?.remarks ? v?.prItem?.remarks : ''}
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </div>
        </CardContents>
      </ContentWrapper>
    </>
  );
};
