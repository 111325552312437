import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import {
  useGetRfqSupplierInvitedQuery,
  useGetContractQuery,
  useGetRfqQuery,
  useGetRfqSupplierSubmissionQuery,
} from 'generated/graphql';
import { useFuseSearch } from '@ifca-root/react-component/src/helpers/Hooks/useSearch';
import { formatDate } from '@contract-root/admin-react/src/helpers/StringNumberFunction/formatDate';
import { amtStr } from '@contract-root/admin-react/src/helpers/StringNumberFunction/numFormatter';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import theme from '@ifca-root/react-component/src/assets/theme';

export const QuotedRFQDetails = (props: any) => {
  const { rfqID, supplierID, ID }: any = useParams();
  let history = useHistory();
  const location = useLocation();
  const viewData: any = location?.state;
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  let id = user?.associateID;

  const {
    loading,
    error,
    data: { getRFQSupplierSubmission } = { getRFQSupplierSubmission: [] },
  } = useGetRfqSupplierSubmissionQuery({
    fetchPolicy: 'network-only',
    variables: { ID: viewData, supplierID: supplierID, rfqID: rfqID },
  });

  const {
    loading: rfqLoading,
    error: rfqError,
    data: { getRFQSupplierInvited } = { getRFQSupplierInvited: [] },
  } = useGetRfqSupplierInvitedQuery({
    fetchPolicy: 'network-only',
    variables: { supplierID: id },
  });

  const {
    loading: conLoading,
    error: conError,
    data: { getContract } = { getContract: [] },
  } = useGetContractQuery({
    fetchPolicy: 'network-only',
    variables: { ID: getRFQSupplierInvited[0]?.contractID },
  });

  useEffect(() => {
    if (getRFQSupplierSubmission && getRFQSupplierSubmission?.length > 0) {
      setOriginalListing(getRFQSupplierSubmission[0]?.rfqSubmissionItem);
    }
  }, [getRFQSupplierSubmission]);

  return (
    <>
      {loading && <Loading />}
      {conLoading && <Loading />}
      {rfqLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/rfq/${rfqID}/quoted-rfq`)}
        smTitle={'Supplier App'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'RFQ Invitees' },
          { name: 'Quoted RFQ', current: true },
        ]}
        rightRouteSegments={[{ name: `Details`, current: true }]}
      />
      <DynamicSubHeader
        style={{ top: isDesktop ? 100 : 65 }}
        title={getContract[0]?.title} // title text
        rightText={getRFQSupplierInvited[0]?.docNo}
        infoLine={
          <>
            <span className="desc p-r-10">
              {formatDate(getRFQSupplierInvited[0]?.docDate)}
            </span>
            <span className="desc">
              {getRFQSupplierSubmission[0]?.supplier?.name}
            </span>
          </>
        }
        rightInfoLine={`Exp: ${formatDate(
          getRFQSupplierInvited[0]?.expectedDate,
        )}`}
      />
      <ContentWrapper style={{ marginTop: isDesktop ? 129 : 60 }}>
        <CardContents section={{ header: { title: 'Quoted RFQ Details' } }}>
          <div className="content-wrap">
            <div className="desc">Quotation Date</div>
            <div className="xsTitle">
              {formatDate(getRFQSupplierSubmission[0]?.submittedDate)}
            </div>
          </div>
          <div className="content-wrap">
            <div className="desc">Credit Term (Days)</div>
            <div className="xsTitle">
              {getRFQSupplierSubmission[0]?.creditTerm}
            </div>
          </div>
          <div className="content-wrap">
            <div className="desc">Effective Date</div>
            <div className="xsTitle">
              {formatDate(getRFQSupplierSubmission[0]?.effectiveDate)}
            </div>
          </div>
          <div className="content-wrap">
            <div className="desc">Expiry Date</div>
            <div className="xsTitle">
              {formatDate(getRFQSupplierSubmission[0]?.expiryDate)}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Remark</div>
            <div className="xsTitle">
              {getRFQSupplierSubmission[0]?.remarks}
            </div>
          </div>
        </CardContents>

        <CardContents>
          <>
            <div className="rm-padding table-wrap">
              <List className="core-list content-wrap full " disablePadding>
                <ListItem className="table-header">
                  <ListItemText
                    primary={
                      <Grid container justify="flex-start">
                        <Grid item xs={1}>
                          <div
                            className="xsTitle flex-space"
                            style={{ color: '#ff9800' }}
                          >
                            No.
                          </div>
                        </Grid>
                        <Grid item xs={5}>
                          <div
                            className="xsTitle flex-space"
                            style={{ color: '#ff9800' }}
                          >
                            Items
                          </div>
                        </Grid>

                        <Grid item xs={2}>
                          <div
                            className="xsTitle"
                            style={{ color: '#ff9800', textAlign: 'center' }}
                          >
                            Unit Price
                          </div>
                        </Grid>

                        <Grid item xs={4}>
                          <div
                            className="xsTitle"
                            style={{ color: '#ff9800', textAlign: 'center' }}
                          >
                            Total Price
                          </div>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                {filteredList?.map((v, i) => (
                  <ListItem
                    style={{ boxShadow: 'none', marginBottom: '0px' }}
                    divider={true}
                  >
                    <Grid container justify="flex-start">
                      <Grid item xs={1}>
                        <div className="desc">{`${i + 1}.`}</div>
                      </Grid>
                      <Grid item xs={5}>
                        <div className="xsTitle flex-space">
                          {v?.rfqItem?.prItem?.costItem?.name}
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <div className="desc highlight-text">
                          {' '}
                          <div
                            className="highlight-text"
                            style={{ textAlign: 'center' }}
                          >
                            {amtStr(v?.unitPrice)}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={4}>
                        <div className="desc highlight-text">
                          <div
                            className="highlight-text"
                            style={{ textAlign: 'center' }}
                          >
                            {amtStr(v?.rfqItem?.quantity * v?.unitPrice)}
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container justify="flex-start">
                      <div className="desc flex-space">
                        {amtStr(v?.rfqItem?.quantity)} (
                        {v?.rfqItem?.prItem?.uom?.code})
                      </div>
                    </Grid>
                    <Grid container justify="flex-start">
                      <Grid>
                        <div className="desc text-noflow">
                          {!!v?.remarks ? v?.remarks : ''}
                        </div>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </div>
          </>
        </CardContents>
      </ContentWrapper>
    </>
  );
};
